/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React, { useState } from 'react';
import {
  FormText, Card, CardBody, CardText, Col, FormGroup, CustomInput, Label, Row,
} from 'reactstrap';
import { percentiles } from '@hbrisk/sp3-risk-model-support/models/recoveryTime.js';
import BackButton from '#components/pages/Models/Model/sections/support/buttons/BackButton.jsx';
import NextButton from '#components/pages/Models/Model/sections/support/buttons/NextButton.jsx';
import Tip from '#components/support/tooltips/Tip/index.jsx';
import RepairTimeTable from './RepairTimeTable/index.js';
import RepairTimePlot from './RepairTimePlot/index.js';

type Props = {|
  heading: string,
  modelId: string,
  IsATC138: string,
  showContent: boolean,
  onBack: Function,
  onNext: Function,
|};

const getOptionDescription = (p) => (
  p === 50
    ? 'Median (50th percentile)'
    : `${p}th percentile`);

const getHeaderDescription = (p) => (
  p === 'p50'
    ? 'Median'
    : `${p.substring(1)}th percentile`
);

const percentileOptions = percentiles.map((percentile) => (
  <option value={`p${percentile}`}>{getOptionDescription(percentile)}</option>
));

const RepairTimeSection = ({
  heading, modelId, IsATC138, showContent, onBack, onNext,
}: Props): Element<'div'> => {
  const [percentile, setPercentile] = useState('p50');

  const handleSelect = (e) => {
    setPercentile(e.target.value);
  };

  const content = (
    <>
      <div className="pt-2 pb-2">
        {
          IsATC138
            ? (
              <>
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="percentile">Percentile</Label>
                      <CustomInput
                        type="select"
                        id="percentile"
                        name="percentile"
                        value={percentile}
                        onChange={handleSelect}
                      >
                        {percentileOptions}
                      </CustomInput>
                    </FormGroup>
                  </Col>
                </Row>
                <h5>
                  ATC-138 Recovery Time
                  {' - '}
                  {getHeaderDescription(percentile)}
                  {' '}
                  <Tip tipId="atc138Output" />
                </h5>
                <FormText id="withImpedingFactors" color="muted">
                  * Includes selected impeding factors
                </FormText>
              </>
            ) : (
              <>
                <h5>
                  REDi Repair Time (2013)
                  {' - '}
                  {getHeaderDescription(percentile)}
                  {' '}
                  <Tip tipId="rediOutput" />
                </h5>
                <FormText id="withoutImpedingFactors" color="muted">
                  * Excludes impeding factors
                </FormText>
              </>
            )
        }
        <RepairTimePlot modelId={modelId} percentile={percentile} />
      </div>
      <div className="pt-2 pb-2">
        <RepairTimeTable modelId={modelId} percentile={percentile} />
      </div>
    </>
  );
  const empty = (
    <Row className="pt-3 pb-3">
      <Col>
        <Card>
          <CardBody>
            <CardText>
              Run your model to view recovery time results.
            </CardText>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
  return (
    <div>
      <h2>
        {heading}
        {' '}
        <Tip tipId="buildingRepairTime" />
      </h2>
      {
        showContent
          ? content
          : empty
      }
      <BackButton onClick={onBack} />
      <NextButton onClick={onNext} />
    </div>
  );
};

export default RepairTimeSection;
