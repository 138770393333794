/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { ATC_138_ID } from '@hbrisk/sp3-risk-model-support/models/repairTimeMethods.js';
import { FORM_INPUT_NAMES } from '@hbrisk/sp3-risk-model-support/models/app/attributes/names/index.js';
import {
  emptyOr0to1,
  emptyOr0to10,
  emptyOr0to100,
  emptyOr100to10M,
  emptyOr1to10,
  emptyOr1to100,
  emptyOr1to100k,
  emptyOr1to10k,
  emptyOr2to100,
  emptyOrAlmost0to25,
  emptyOrAlmost0toAlmost100,
  emptyOrMaxLength100,
  emptyOrMaxLength1000,
  emptyOrMaxLength300,
  emptyOrNonNegativeInt,
  emptyOrp001to1K,
  emptyOrp01to10,
  emptyOrp01to100,
  emptyOrp01to20,
  emptyOrp1to10,
  required,
} from '@hbrisk/sp3-risk-model-support/utility/form/validation/fieldValidators/index.js';
import getComponentQuestionFields from '#support/models/getComponentQuestionFields.js';
import { DAMAGE_CORRELATION_MODEL_ATC_138_ID } from '#constants/models/damageCorrelationModels.js';
import {
  buildingTypeByDir,
  coordinatesAreInUS,
  emptyOrInt1ToNumberOfStories,
  greaterThanMinCodeYear,
  greaterThanMinYearOfConstruction,
  maxStoriesForBuildingType,
  yearAfterConstructionOrCodeYear,
} from '#support/models/form/modelForm/validators/index.js';
import { COLLAPSE_METHOD_FEMA } from '#constants/models/collapseDefinitionMethods.js';

import {
  clearBuildingTypeIfInconsistentWithCountry,
  clearOnCountryChange,
  includeRepairTimeIsTrue,
  medianIfCountryIsJapan,
  AND,
  repairTimeMethodIsATC138,
  NOT,
  countryIsJapan,
  atLeastOneBuildingTypeIsEmptyOrHasFEMATypeIn,
  collapseDefinitionMethodIsMedian,
  everyBuildingTypeIsWoodLightFrame,
  everyBuildingTypeIsWoodOrSteelLightFrame,
  atLeastOneBuildingTypeIsEmptyOrWoodOrSteelLightFrame,
  atLeastOneBuildingTypeIsEmptyOrConcreteShearWall,
  atLeastOneBuildingTypeIsEmptyOrMasonryShearWall,
  collapseDefinitionMethodIsEmptyOrFEMA,
  isWoodLightFrame,
  isSimpsonYieldLinkBraceFrame,
  isTaylorDampedMomentFrame,
  isInfilled,
  includeSurgeDemandIsYes,
  isEmptyOrRWFD,
  needElevatorForFunctionIsYes,
  everyBuildingTypeIsLightFrameOrRWFD,
  collapseDefinitionMethodIsMCE,
  includeRetrofitIsTrue,
  isGreaterThanOrEqualToOne,
  numberOfStoriesIsOne,
  designCodeYearVisibilityReducer,
  yearOfConstructionVisibilityReducer,
  nonStructuralRetrofitCodeYearVisibilityReducer,
  nonStructuralRetrofitYearVisibilityReducer,
  structuralRetrofitCodeYearVisibilityReducer, structuralRetrofitYearVisibilityReducer,
  useSmartEquipmentComponentsIsYes,
  prequalifiedDesignCodeYearIsOverride,
  equipmentPrequalifiedIsYes,
} from '../support/fieldLevelReducers/index.js';

import {
  ADDITIONAL_NOTES_SECTION,
  ANALYSIS_OPTIONS_SECTION,
  BUILDING_DESIGN_SECTION,
  BUILDING_LAYOUT_SECTION,
  BUILDING_MODIFIERS_CHECKLIST_SECTION,
  BUILDING_STABILITY_SECTION,
  COMPONENT_CHECKLIST_SECTION,
  COMPONENT_GROUP_MODIFIERS_SECTION,
  MODEL_AND_SITE_INFO_SECTION,
  PRIMARY_BUILDING_INFO_SECTION,
  REPAIR_TIME_OPTIONS_SECTION,
  SITE_COORDINATES_SECTION,
  STRUCTURAL_PROPERTIES_SECTION,
} from '../../../modelPage/sectionIds.js';

const fields = [
  ...getComponentQuestionFields(),
  {
    name: 'allowFireWatchToBeSufficient',
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138
      ),
    },
  },
  {
    name: 'allowTemporaryRepairs',
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138
      ),
    },
  },
  {
    name: 'allowTemporaryShoring',
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138
      ),
    },
  },
  {
    name: 'baseShearStrengthDir1',
    validation: emptyOrAlmost0to25,
    section: STRUCTURAL_PROPERTIES_SECTION,
  },
  {
    name: 'baseShearStrengthDir2',
    validation: emptyOrAlmost0to25,
    section: STRUCTURAL_PROPERTIES_SECTION,
  },
  {
    name: 'buildingAspectRatio',
    validation: emptyOrp1to10,
    section: BUILDING_LAYOUT_SECTION,
  },
  {
    name: 'buildingModsColumnPierSpandrelRatio',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: AND(
        NOT(countryIsJapan),
        atLeastOneBuildingTypeIsEmptyOrHasFEMATypeIn([
          'C1', 'C2', 'C3', 'PC1', 'PC2', 'RM1', 'RM2',
        ])
      ),
    },
  },
  {
    name: 'buildingModsColumnRatioLessThanTypical',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: AND(
        NOT(countryIsJapan),
        atLeastOneBuildingTypeIsEmptyOrHasFEMATypeIn([
          'C1', 'C2', 'C3', 'PC1', 'PC2', 'RM1', 'RM2',
        ])
      ),
    },
  },
  {
    name: 'buildingModsConstructionQuality',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: NOT(countryIsJapan),
    },
  },
  {
    name: 'buildingModsCrossGrainOopConn',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: AND(
        NOT(countryIsJapan),
        atLeastOneBuildingTypeIsEmptyOrHasFEMATypeIn(['PC1', 'RM1'])
      ),
    },
  },
  {
    name: 'buildingModsDiaphramOpen',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: NOT(countryIsJapan),
    },
  },
  {
    name: 'buildingModsFlatPlateAsBeam',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: AND(
        NOT(countryIsJapan),
        atLeastOneBuildingTypeIsEmptyOrHasFEMATypeIn(['C1'])
      ),
    },
  },
  {
    name: 'buildingModsFullStoryGrade',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: AND(
        NOT(countryIsJapan),
        atLeastOneBuildingTypeIsEmptyOrHasFEMATypeIn([
          'W2', 'S1', 'S2', 'S3', 'S4', 'S5',
          'C1', 'C2', 'C3',
          'PC1', 'PC2', 'RM1', 'RM2',
        ])
      ),
    },
  },
  {
    name: 'buildingModsFullStoryGradeWLF',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: AND(
        NOT(countryIsJapan),
        atLeastOneBuildingTypeIsEmptyOrHasFEMATypeIn(['W1'])
      ),
    },
  },
  {
    name: 'buildingModsInteriorWallDensity',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: AND(
        NOT(countryIsJapan),
        atLeastOneBuildingTypeIsEmptyOrHasFEMATypeIn(['PC1', 'RM1'])
      ),
    },
  },
  {
    name: 'buildingModsKBracing',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: AND(
        NOT(countryIsJapan),
        atLeastOneBuildingTypeIsEmptyOrHasFEMATypeIn(['S2'])
      ),
    },
  },
  {
    name: 'buildingModsMaterialDeterioration',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: NOT(countryIsJapan),
    },
  },
  {
    name: 'buildingModsNonParallel',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: NOT(countryIsJapan),
    },
  },
  {
    name: 'buildingModsNonStructuralConstructionQuality',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: NOT(countryIsJapan),
    },
  },
  {
    name: 'buildingModsOopOffset',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: AND(
        NOT(countryIsJapan),
        atLeastOneBuildingTypeIsEmptyOrHasFEMATypeIn(['C1', 'C2'])
      ),
    },
  },
  {
    name: 'buildingModsOtherPlanIrregularity',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: NOT(countryIsJapan),
    },
  },
  {
    name: 'buildingModsOtherVerticalIrregularity',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: NOT(countryIsJapan),
    },
  },
  {
    name: 'buildingModsRedundancy',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: NOT(countryIsJapan),
    },
  },
  {
    name: 'buildingModsReentrant',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: NOT(countryIsJapan),
    },
  },
  {
    name: 'buildingModsSetbackInboard',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: NOT(countryIsJapan),
    },
  },
  {
    name: 'buildingModsSetbackInPlaneLfrs',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: NOT(countryIsJapan),
    },
  },
  {
    name: 'buildingModsSetbackOutboard',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: NOT(countryIsJapan),
    },
  },
  {
    name: 'buildingModsSplitLevel',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: NOT(countryIsJapan),
    },
  },
  {
    name: 'buildingReplacementTime',
    section: REPAIR_TIME_OPTIONS_SECTION,
    validation: [emptyOr1to10k],
    reducers: {
      visible: includeRepairTimeIsTrue,
    },
  },
  {
    name: 'buildingSquareFootage',
    validation: emptyOr100to10M,
    section: BUILDING_LAYOUT_SECTION,
  },
  {
    name: 'buildingTypeDir1',
    validation: required,
    section: PRIMARY_BUILDING_INFO_SECTION,
    reducers: {
      value: clearBuildingTypeIfInconsistentWithCountry('buildingTypeDir1'),
    },
  },
  {
    name: 'buildingTypeDir2',
    validation: [required, buildingTypeByDir('buildingTypeDir1')],
    section: PRIMARY_BUILDING_INFO_SECTION,
    reducers: {
      value: clearBuildingTypeIfInconsistentWithCountry('buildingTypeDir2'),
    },
  },
  {
    name: 'cashOnHand',
    section: REPAIR_TIME_OPTIONS_SECTION,
    validation: [emptyOr0to100],
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138
      ),
    },
  },
  {
    name: 'ceilingsCapacityMultiplier',
    validation: emptyOrp01to20,
    section: COMPONENT_GROUP_MODIFIERS_SECTION,
  },
  {
    name: 'ceilingsCostMultiplier',
    section: COMPONENT_GROUP_MODIFIERS_SECTION,
  },
  {
    name: 'city',
    section: MODEL_AND_SITE_INFO_SECTION,
    reducers: {
      value: clearOnCountryChange('city'),
    },
  },
  {
    name: 'collapseCapacityBeta',
    validation: [emptyOrp01to10],
    section: BUILDING_STABILITY_SECTION,
  },
  {
    name: 'collapseCapacityMedian',
    validation: [emptyOrp001to1K],
    section: BUILDING_STABILITY_SECTION,
    reducers: {
      visible: collapseDefinitionMethodIsMedian,
    },
  },
  {
    name: 'collapseDefinitionMethod',
    defaultValue: COLLAPSE_METHOD_FEMA,
    validation: [required],
    section: BUILDING_STABILITY_SECTION,
    reducers: {
      value: medianIfCountryIsJapan('collapseDefinitionMethod'),
    },
  },
  {
    name: 'componentImportanceFactor',
    validation: emptyOr1to10,
    section: BUILDING_DESIGN_SECTION,
    reducers: {
      visible: NOT(countryIsJapan),
    },
  },
  {
    name: FORM_INPUT_NAMES.COMPONENT_SOFT_COST_MODIFIER,
    section: ANALYSIS_OPTIONS_SECTION,
    validation: emptyOrp01to20,
  },
  {
    name: 'concreteShearWallsLoadBearing',
    section: COMPONENT_CHECKLIST_SECTION,
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138,
        atLeastOneBuildingTypeIsEmptyOrConcreteShearWall,
        NOT(countryIsJapan)
      ),
    },
  },
  {
    name: 'costPerSqFt',
    validation: emptyOr1to100k,
    section: BUILDING_LAYOUT_SECTION,
  },
  {
    name: 'country',
    defaultValue: 'US',
    section: MODEL_AND_SITE_INFO_SECTION,
  },
  {
    name: 'damageCorrelationModel',
    defaultValue: DAMAGE_CORRELATION_MODEL_ATC_138_ID,
    section: ANALYSIS_OPTIONS_SECTION,
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138
      ),
    },
  },
  {
    name: 'dateCostMultiplier',
    validation: emptyOrp01to20,
    section: ANALYSIS_OPTIONS_SECTION,
  },
  {
    name: 'designCodeYear',
    defaultVisible: false,
    validation: [required, greaterThanMinCodeYear],
    section: PRIMARY_BUILDING_INFO_SECTION,
    reducers: {
      visible: designCodeYearVisibilityReducer,
    },
  },
  {
    name: 'detailLevelDir1',
    section: BUILDING_DESIGN_SECTION,
  },
  {
    name: 'detailLevelDir2',
    section: BUILDING_DESIGN_SECTION,
  },
  {
    name: FORM_INPUT_NAMES.DOOR_RACKING_AFFECTS_EGRESS,
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138,
      ),
    },
  },
  {
    name: 'elevatorsCapacityMultiplier',
    validation: emptyOrp01to20,
    section: COMPONENT_GROUP_MODIFIERS_SECTION,
  },
  {
    name: 'elevatorsCostMultiplier',
    section: COMPONENT_GROUP_MODIFIERS_SECTION,
  },
  {
    name: 'equipmentPrequalified',
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138,
        useSmartEquipmentComponentsIsYes,
      ),
    },
  },
  {
    name: 'exteriorFinishesCapacityMultiplier',
    validation: emptyOrp01to20,
    section: COMPONENT_GROUP_MODIFIERS_SECTION,
    reducers: {
      visible: NOT(everyBuildingTypeIsWoodOrSteelLightFrame),
    },
  },
  {
    name: 'exteriorFinishesCostMultiplier',
    section: COMPONENT_GROUP_MODIFIERS_SECTION,
    reducers: {
      visible: NOT(everyBuildingTypeIsWoodOrSteelLightFrame),
    },
  },
  {
    name: 'exteriorWallsCapacityMultiplier',
    validation: emptyOrp01to20,
    section: COMPONENT_GROUP_MODIFIERS_SECTION,
    reducers: {
      visible: atLeastOneBuildingTypeIsEmptyOrWoodOrSteelLightFrame,
    },
  },
  {
    name: 'exteriorWallsCostMultiplier',
    section: COMPONENT_GROUP_MODIFIERS_SECTION,
    reducers: {
      visible: atLeastOneBuildingTypeIsEmptyOrWoodOrSteelLightFrame,
    },
  },
  {
    name: 'fema154Score',
    section: BUILDING_STABILITY_SECTION,
    validation: [emptyOr0to10],
    reducers: {
      visible: AND(
        NOT(countryIsJapan),
        collapseDefinitionMethodIsEmptyOrFEMA,
      ),
    },
  },
  {
    name: 'firstStoryFullWallRatioDir1',
    section: BUILDING_LAYOUT_SECTION,
    validation: [emptyOr0to1],
    reducers: {
      visible: AND(
        NOT(countryIsJapan),
        isWoodLightFrame('buildingTypeDir1')
      ),
    },
  },
  {
    name: 'firstStoryFullWallRatioDir2',
    section: BUILDING_LAYOUT_SECTION,
    validation: [emptyOr0to1],
    reducers: {
      visible: AND(
        NOT(countryIsJapan),
        isWoodLightFrame('buildingTypeDir2')
      ),
    },
  },
  {
    name: 'firstStoryHeight',
    validation: emptyOr1to100,
    section: BUILDING_LAYOUT_SECTION,
  },
  {
    name: 'firstStoryInfilledWallRatioDir1',
    section: BUILDING_LAYOUT_SECTION,
    validation: [emptyOr0to1],
    reducers: {
      visible: AND(
        NOT(countryIsJapan),
        isInfilled('buildingTypeDir1'),
      ),
    },
  },
  {
    name: 'firstStoryInfilledWallRatioDir2',
    section: BUILDING_LAYOUT_SECTION,
    validation: [emptyOr0to1],
    reducers: {
      visible: AND(
        NOT(countryIsJapan),
        isInfilled('buildingTypeDir2'),
      ),
    },
  },
  {
    name: 'hvacCapacityMultiplier',
    validation: emptyOrp01to20,
    section: COMPONENT_GROUP_MODIFIERS_SECTION,
  },
  {
    name: 'hvacCostMultiplier',
    section: COMPONENT_GROUP_MODIFIERS_SECTION,
  },
  {
    name: 'includeFloodingImpact',
    section: REPAIR_TIME_OPTIONS_SECTION,
    defaultValue: 'yes',
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138,
      ),
    },
  },
  {
    name: 'includeImpedanceContractor',
    defaultValue: true,
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: includeRepairTimeIsTrue,
    },
  },
  {
    name: 'includeImpedanceEngineering',
    defaultValue: true,
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: includeRepairTimeIsTrue,
    },
  },
  {
    name: 'includeImpedanceFinancing',
    defaultValue: true,
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: includeRepairTimeIsTrue,
    },
  },
  {
    name: 'includeImpedanceInspection',
    defaultValue: true,
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: includeRepairTimeIsTrue,
    },
  },
  {
    name: 'includeImpedanceLongLeadTime',
    defaultValue: true,
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138
      ),
    },
  },
  {
    name: 'includeImpedancePermitting',
    defaultValue: true,
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: includeRepairTimeIsTrue,
    },
  },
  {
    name: 'includeCollapse',
    defaultValue: false,
    section: ANALYSIS_OPTIONS_SECTION,
  },
  {
    name: 'includeRepairTime',
    defaultVisible: false,
    defaultValue: false,
    permission: 'recoveryTime',
    section: ANALYSIS_OPTIONS_SECTION,
  },
  {
    name: 'includeResidualDrift',
    defaultValue: true,
    section: BUILDING_DESIGN_SECTION,
  },
  {
    name: 'includeRetrofit',
    defaultValue: false,
    section: BUILDING_DESIGN_SECTION,
  },
  {
    name: 'includeSurgeDemand',
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138
      ),
    },
  },
  {
    name: 'includeUSRC',
    defaultValue: false,
    section: ANALYSIS_OPTIONS_SECTION,
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        NOT(countryIsJapan)
      ),
    },
  },
  {
    name: 'interiorWallsCapacityMultiplier',
    validation: emptyOrp01to20,
    section: COMPONENT_GROUP_MODIFIERS_SECTION,
    reducers: {
      visible: atLeastOneBuildingTypeIsEmptyOrWoodOrSteelLightFrame,
    },
  },
  {
    name: 'interiorWallsCostMultiplier',
    section: COMPONENT_GROUP_MODIFIERS_SECTION,
    reducers: {
      visible: atLeastOneBuildingTypeIsEmptyOrWoodOrSteelLightFrame,
    },
  },
  {
    name: 'isDenseUrbanArea',
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138,
        includeSurgeDemandIsYes
      ),
    },
  },
  {
    name: 'lat',
    validation: [required, coordinatesAreInUS],
    section: SITE_COORDINATES_SECTION,
  },
  {
    name: 'lightingCapacityMultiplier',
    validation: emptyOrp01to20,
    section: COMPONENT_GROUP_MODIFIERS_SECTION,
  },
  {
    name: 'lightingCostMultiplier',
    section: COMPONENT_GROUP_MODIFIERS_SECTION,
  },
  {
    name: 'linesOfResistanceDir1',
    validation: [emptyOrNonNegativeInt],
    section: STRUCTURAL_PROPERTIES_SECTION,
    reducers: {
      visible: isEmptyOrRWFD('buildingTypeDir1'),
    },
  },
  {
    name: 'linesOfResistanceDir2',
    validation: [emptyOrNonNegativeInt],
    section: STRUCTURAL_PROPERTIES_SECTION,
    reducers: {
      visible: isEmptyOrRWFD('buildingTypeDir2'),
    },
  },
  {
    name: 'lng',
    validation: [required, coordinatesAreInUS],
    section: SITE_COORDINATES_SECTION,
  },
  {
    name: 'masonryShearWallsLoadBearing',
    section: COMPONENT_CHECKLIST_SECTION,
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138,
        atLeastOneBuildingTypeIsEmptyOrMasonryShearWall,
        NOT(countryIsJapan)
      ),
    },
  },
  {
    name: 'maxWalkableStory',
    section: REPAIR_TIME_OPTIONS_SECTION,
    validation: [emptyOrInt1ToNumberOfStories],
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138,
        needElevatorForFunctionIsYes,
      ),
    },
  },
  {
    name: 'minimumNumberOfExitsRequired',
    section: REPAIR_TIME_OPTIONS_SECTION,
    validation: emptyOr0to100,
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138
      ),
    },
  },
  {
    name: 'mitigationContractor',
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: includeRepairTimeIsTrue,
    },
  },
  {
    name: 'mitigationEngineer',
    defaultValue: false,
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: includeRepairTimeIsTrue,
    },
  },
  {
    name: 'mitigationFundingSource',
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: includeRepairTimeIsTrue,
    },
  },
  {
    name: 'mitigationInspector',
    defaultValue: false,
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: includeRepairTimeIsTrue,
    },
  },
  {
    name: 'modelName',
    validation: [required, emptyOrMaxLength300],
    section: MODEL_AND_SITE_INFO_SECTION,
  },
  {
    name: 'needCoolingForFunction',
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138
      ),
    },
  },
  {
    name: 'needCoolingForReoccupancy',
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138
      ),
    },
  },
  {
    name: 'needElectricalPowerForFunction',
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138
      ),
    },
  },
  {
    name: 'needElectricalPowerForReoccupancy',
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138
      ),
    },
  },
  {
    name: 'needElevatorForFunction',
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138
      ),
    },
  },
  {
    name: 'needExhaustForFunction',
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138
      ),
    },
  },
  {
    name: 'needExhaustForReoccupancy',
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138
      ),
    },
  },
  {
    name: 'needHeatingForFunction',
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138
      ),
    },
  },
  {
    name: 'needHeatingForReoccupancy',
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138
      ),
    },
  },
  {
    name: 'needPotableWaterForFunction',
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138
      ),
    },
  },
  {
    name: 'needPotableWaterForReoccupancy',
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138
      ),
    },
  },
  {
    name: 'needSanitationPlumbingForFunction',
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138
      ),
    },
  },
  {
    name: 'needSanitationPlumbingForReoccupancy',
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138
      ),
    },
  },
  {
    name: 'needVentilationForFunction',
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138
      ),
    },
  },
  {
    name: 'needVentilationForReoccupancy',
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138
      ),
    },
  },
  {
    name: 'nonStructuralRetrofitCodeYear',
    validation: yearAfterConstructionOrCodeYear,
    section: BUILDING_DESIGN_SECTION,
    reducers: {
      visible: nonStructuralRetrofitCodeYearVisibilityReducer,
    },
  },
  {
    name: 'nonStructuralRetrofitLevel',
    validation: emptyOr1to100,
    section: BUILDING_DESIGN_SECTION,
    reducers: {
      visible: includeRetrofitIsTrue,
    },
  },
  {
    name: 'nonStructuralRetrofitYear',
    validation: yearAfterConstructionOrCodeYear,
    section: BUILDING_DESIGN_SECTION,
    reducers: {
      visible: nonStructuralRetrofitYearVisibilityReducer,
    },
  },
  {
    name: 'notes',
    validation: emptyOrMaxLength1000,
    section: ADDITIONAL_NOTES_SECTION,
  },
  {
    name: 'numberOfStories',
    validation: [required, maxStoriesForBuildingType],
    section: PRIMARY_BUILDING_INFO_SECTION,
  },
  {
    name: 'occupancy',
    validation: required,
    section: PRIMARY_BUILDING_INFO_SECTION,
  },
  {
    name: 'occupancyCostMultiplier',
    validation: emptyOrp01to20,
    section: ANALYSIS_OPTIONS_SECTION,
  },
  {
    name: 'partitionWallsCapacityMultiplier',
    validation: emptyOrp01to20,
    section: COMPONENT_GROUP_MODIFIERS_SECTION,
    reducers: {
      visible: NOT(everyBuildingTypeIsWoodOrSteelLightFrame),
    },
  },
  {
    name: 'partitionWallsCostMultiplier',
    section: COMPONENT_GROUP_MODIFIERS_SECTION,
    reducers: {
      visible: NOT(everyBuildingTypeIsWoodOrSteelLightFrame),
    },
  },
  {
    name: 'percentGlazing',
    validation: emptyOr0to100,
    section: COMPONENT_CHECKLIST_SECTION,
    reducers: {
      visible: NOT(everyBuildingTypeIsLightFrameOrRWFD),
    },
  },
  {
    name: 'percentOfExitsRequired',
    section: REPAIR_TIME_OPTIONS_SECTION,
    validation: emptyOr0to100,
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138
      ),
    },
  },
  {
    name: 'percentOfPartitionsAreFireBrake',
    section: COMPONENT_CHECKLIST_SECTION,
    validation: emptyOr0to100,
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138,
        NOT(countryIsJapan),
        NOT(everyBuildingTypeIsWoodLightFrame)
      ),
    },
  },
  {
    name: 'period1Dir1',
    validation: emptyOrAlmost0to25,
    section: STRUCTURAL_PROPERTIES_SECTION,
  },
  {
    name: 'period1Dir2',
    validation: emptyOrAlmost0to25,
    section: STRUCTURAL_PROPERTIES_SECTION,
  },
  {
    name: 'pipingCapacityMultiplier',
    validation: emptyOrp01to20,
    section: COMPONENT_GROUP_MODIFIERS_SECTION,
  },
  {
    name: 'pipingCostMultiplier',
    section: COMPONENT_GROUP_MODIFIERS_SECTION,
  },
  {
    name: 'planTorsion',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
  },
  {
    name: 'prequalifiedAccGround',
    section: REPAIR_TIME_OPTIONS_SECTION,
    validation: [required, emptyOrp01to100],
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138,
        useSmartEquipmentComponentsIsYes,
        equipmentPrequalifiedIsYes,
        prequalifiedDesignCodeYearIsOverride,
      ),
    },
  },
  {
    name: 'prequalifiedAccRoof',
    section: REPAIR_TIME_OPTIONS_SECTION,
    validation: [required, emptyOrp01to100],
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138,
        useSmartEquipmentComponentsIsYes,
        equipmentPrequalifiedIsYes,
        prequalifiedDesignCodeYearIsOverride,
      ),
    },
  },
  {
    name: 'prequalifiedDesignCodeYear',
    section: REPAIR_TIME_OPTIONS_SECTION,
    validation: [required],
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138,
        useSmartEquipmentComponentsIsYes,
        equipmentPrequalifiedIsYes,
      ),
    },
  },
  {
    name: 'probCollapseMce',
    section: BUILDING_STABILITY_SECTION,
    validation: [emptyOrAlmost0toAlmost100],
    reducers: {
      visible: collapseDefinitionMethodIsMCE,
    },
  },
  {
    name: 'projectName',
    validation: [required, emptyOrMaxLength100],
    section: MODEL_AND_SITE_INFO_SECTION,
  },
  {
    name: 'regionCostMultiplier',
    validation: emptyOrp01to20,
    section: ANALYSIS_OPTIONS_SECTION,
  },
  {
    name: 'repairTimeMethod',
    defaultValue: ATC_138_ID,
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: includeRepairTimeIsTrue,
    },
  },
  {
    name: 'riskCategory',
    section: BUILDING_DESIGN_SECTION,
    reducers: {
      visible: NOT(countryIsJapan),
    },
  },
  {
    name: 'seismicImportanceFactor',
    validation: emptyOr1to10,
    section: BUILDING_DESIGN_SECTION,
    reducers: {
      visible: NOT(countryIsJapan),
    },
  },
  {
    name: 'siteClass',
    section: BUILDING_LAYOUT_SECTION,
  },
  {
    name: 'state',
    section: MODEL_AND_SITE_INFO_SECTION,
    reducers: {
      value: clearOnCountryChange('state'),
    },
  },
  {
    name: 'street',
    section: MODEL_AND_SITE_INFO_SECTION,
    reducers: {
      value: clearOnCountryChange('street'),
    },
  },
  {
    name: 'structuralCapacityMultiplier',
    validation: emptyOrp01to20,
    section: COMPONENT_GROUP_MODIFIERS_SECTION,
    reducers: {
      visible: NOT(everyBuildingTypeIsWoodOrSteelLightFrame),
    },
  },
  {
    name: 'structuralCostMultiplier',
    section: COMPONENT_GROUP_MODIFIERS_SECTION,
    reducers: {
      visible: NOT(everyBuildingTypeIsWoodOrSteelLightFrame),
    },
  },
  {
    name: 'structuralRetrofitCodeYear',
    validation: yearAfterConstructionOrCodeYear,
    section: BUILDING_DESIGN_SECTION,
    reducers: {
      visible: structuralRetrofitCodeYearVisibilityReducer,
    },
  },
  {
    name: 'structuralRetrofitLevel',
    validation: emptyOr1to100,
    section: BUILDING_DESIGN_SECTION,
    reducers: {
      visible: includeRetrofitIsTrue,
    },
  },
  {
    name: 'structuralRetrofitYear',
    validation: yearAfterConstructionOrCodeYear,
    section: BUILDING_DESIGN_SECTION,
    reducers: {
      visible: structuralRetrofitYearVisibilityReducer,
    },
  },
  {
    name: 'tdmfDamperConfigDir1',
    section: BUILDING_LAYOUT_SECTION,
    reducers: {
      visible: AND(
        isTaylorDampedMomentFrame('buildingTypeDir1'),
        NOT(countryIsJapan)
      ),
    },
  },
  {
    name: 'tdmfBayLengthDir1',
    validation: [emptyOr2to100],
    section: BUILDING_LAYOUT_SECTION,
    reducers: {
      visible: AND(
        isTaylorDampedMomentFrame('buildingTypeDir1'),
        NOT(countryIsJapan)
      ),
    },
  },
  {
    name: 'tdmfDamperExposureDir1',
    section: BUILDING_LAYOUT_SECTION,
    reducers: {
      visible: AND(
        isTaylorDampedMomentFrame('buildingTypeDir1'),
        NOT(countryIsJapan)
      ),
    },
  },
  {
    name: 'tdmfDamperConfigDir2',
    section: BUILDING_LAYOUT_SECTION,
    reducers: {
      visible: AND(
        isTaylorDampedMomentFrame('buildingTypeDir2'),
        NOT(countryIsJapan)
      ),
    },
  },
  {
    name: 'tdmfBayLengthDir2',
    validation: [emptyOr2to100],
    section: BUILDING_LAYOUT_SECTION,
    reducers: {
      visible: AND(
        isTaylorDampedMomentFrame('buildingTypeDir2'),
        NOT(countryIsJapan)
      ),
    },
  },
  {
    name: 'tdmfDamperExposureDir2',
    section: BUILDING_LAYOUT_SECTION,
    reducers: {
      visible: AND(
        isTaylorDampedMomentFrame('buildingTypeDir2'),
        NOT(countryIsJapan)
      ),
    },
  },
  {
    name: 'typeOfResistanceDir1',
    section: STRUCTURAL_PROPERTIES_SECTION,
    reducers: {
      visible: AND(
        isEmptyOrRWFD('buildingTypeDir1'),
        isGreaterThanOrEqualToOne('linesOfResistanceDir1')
      ),
    },
  },
  {
    name: 'typeOfResistanceDir2',
    section: STRUCTURAL_PROPERTIES_SECTION,
    reducers: {
      visible: AND(
        isEmptyOrRWFD('buildingTypeDir2'),
        isGreaterThanOrEqualToOne('linesOfResistanceDir2'),
      ),
    },
  },
  {
    name: 'upperStoryFullWallRatioDir1',
    section: BUILDING_LAYOUT_SECTION,
    validation: [emptyOr0to1],
    reducers: {
      visible: AND(
        isWoodLightFrame('buildingTypeDir1'),
        NOT(countryIsJapan),
        NOT(numberOfStoriesIsOne),
      ),
    },
  },
  {
    name: 'upperStoryFullWallRatioDir2',
    section: BUILDING_LAYOUT_SECTION,
    validation: [emptyOr0to1],
    reducers: {
      visible: AND(
        isWoodLightFrame('buildingTypeDir2'),
        NOT(countryIsJapan),
        NOT(numberOfStoriesIsOne),
      ),
    },
  },
  {
    name: 'upperStoryHeight',
    validation: emptyOr1to100,
    section: BUILDING_LAYOUT_SECTION,
    reducers: {
      visible: NOT(numberOfStoriesIsOne),
    },
  },
  {
    name: 'upperStoryInfilledWallRatioDir1',
    section: BUILDING_LAYOUT_SECTION,
    validation: [emptyOr0to1],
    reducers: {
      visible: AND(
        isInfilled('buildingTypeDir1'),
        NOT(countryIsJapan),
        NOT(numberOfStoriesIsOne)
      ),
    },
  },
  {
    name: 'upperStoryInfilledWallRatioDir2',
    section: BUILDING_LAYOUT_SECTION,
    validation: [emptyOr0to1],
    reducers: {
      visible: AND(
        isInfilled('buildingTypeDir2'),
        NOT(countryIsJapan),
        NOT(numberOfStoriesIsOne)
      ),
    },
  },
  {
    name: 'useSmartEquipmentComponents',
    section: REPAIR_TIME_OPTIONS_SECTION,
    defaultValue: 'yes',
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138,
      ),
    },
  },
  {
    name: 'useSmartPipingComponents',
    section: REPAIR_TIME_OPTIONS_SECTION,
    defaultValue: 'yes',
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138,
      ),
    },
  },
  {
    name: 'useUpdatedElevatorComponents',
    section: REPAIR_TIME_OPTIONS_SECTION,
    defaultValue: 'yes',
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138,
      ),
    },
  },
  {
    name: 'verticalIrregularity',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
  },
  {
    name: 'yearOfConstruction',
    defaultVisible: true,
    validation: [required, greaterThanMinYearOfConstruction],
    section: PRIMARY_BUILDING_INFO_SECTION,
    reducers: {
      visible: yearOfConstructionVisibilityReducer,
    },
  },
  {
    name: 'ylbfBayLengthDir1',
    validation: [emptyOr2to100],
    section: BUILDING_LAYOUT_SECTION,
    reducers: {
      visible: AND(
        isSimpsonYieldLinkBraceFrame('buildingTypeDir1'),
        NOT(countryIsJapan)
      ),
    },
  },
  {
    name: 'ylbfBayLengthDir2',
    validation: [emptyOr2to100],
    section: BUILDING_LAYOUT_SECTION,
    reducers: {
      visible: AND(
        isSimpsonYieldLinkBraceFrame('buildingTypeDir2'),
        NOT(countryIsJapan)
      ),
    },
  },
  {
    name: 'ylbfBraceConfigDir1',
    section: BUILDING_LAYOUT_SECTION,
    reducers: {
      visible: AND(
        isSimpsonYieldLinkBraceFrame('buildingTypeDir1'),
        NOT(countryIsJapan)
      ),
    },
  },
  {
    name: 'ylbfBraceConfigDir2',
    section: BUILDING_LAYOUT_SECTION,
    reducers: {
      visible: AND(
        isSimpsonYieldLinkBraceFrame('buildingTypeDir2'),
        NOT(countryIsJapan)
      ),
    },
  },
  {
    name: 'ylbfBraceExposureDir1',
    section: BUILDING_LAYOUT_SECTION,
    reducers: {
      visible: AND(
        isSimpsonYieldLinkBraceFrame('buildingTypeDir1'),
        NOT(countryIsJapan)
      ),
    },
  },
  {
    name: 'ylbfBraceExposureDir2',
    section: BUILDING_LAYOUT_SECTION,
    reducers: {
      visible: AND(
        isSimpsonYieldLinkBraceFrame('buildingTypeDir2'),
        NOT(countryIsJapan)
      ),
    },
  },
  {
    name: 'ylbfNumFusesPerBraceDir1',
    section: BUILDING_LAYOUT_SECTION,
    reducers: {
      visible: AND(
        isSimpsonYieldLinkBraceFrame('buildingTypeDir1'),
        NOT(countryIsJapan)
      ),
    },
  },
  {
    name: 'ylbfNumFusesPerBraceDir2',
    section: BUILDING_LAYOUT_SECTION,
    reducers: {
      visible: AND(
        isSimpsonYieldLinkBraceFrame('buildingTypeDir2'),
        NOT(countryIsJapan)
      ),
    },
  },
  {
    name: 'zip',
    section: MODEL_AND_SITE_INFO_SECTION,
    reducers: {
      value: clearOnCountryChange('zip'),
    },
  },
];

export default fields;
