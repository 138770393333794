/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { ComponentType, Element } from 'react';

import React, { Fragment } from 'react';
import {
  Col,
  Row,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import get from 'lodash/fp/get.js';
import getAutofiller from '@hbrisk/sp3-risk-model-support/utility/form/getAutofiller/index.js';
import Field from '#components/pages/Models/Model/sections/support/forms/Field/index.js';
import RenderInput from '#components/support/forms/RenderInput/index.jsx';
import FieldPopulationToggleLink from '#components/pages/Models/Model/sections/support/forms/fieldPopulationToggles/FieldPopulationToggleLink/index.js';
import FieldFeedback from '#components/support/forms/FieldFeedback/index.js';
import Tip from '#components/support/tooltips/Tip/index.jsx';
import { modelTypesByForm } from '#src/js/constants/models/modelTypes/index.js';
import { flexRow } from './index.module.scss';

type Props = {|
  name: string,
  form: string,
  options: {
    externalAutofillSource?: string,
    labelText?: string | Element<any>,
    columnClass?: string,
    columnSize?: number,
    includeToolTip?: boolean,
    toolTipId?: string,
    toolTipTargetId?: string,
    includeFormGroup?: boolean,
    includeFormatting?: boolean,
    showAutopopulationToggle?: boolean,
    ComponentType: string | ComponentType<any>,
    componentProps?: any,
    inputGroup?: {
      addonType: 'append' | 'prepend',
      addonText: string,
    },
    format?: Function,
    normalize?: Function,
  },
|};

const withInputGroup = (inputGroup, comp) => {
  const addonType = get('addonType', inputGroup);
  const addonText = get('addonText', inputGroup);

  if (addonType === 'prepend') {
    return (
      <InputGroup>
        <InputGroupAddon addonType="prepend">
          <InputGroupText>{addonText}</InputGroupText>
        </InputGroupAddon>
        {comp}
      </InputGroup>
    );
  }
  if (addonType === 'append') {
    return (
      <InputGroup>
        {comp}
        <InputGroupAddon addonType="append">
          <InputGroupText>{addonText}</InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    );
  }
  return comp;
};

type GridFieldElement = Element<'div'> | Element<typeof Fragment> | Element<typeof FormGroup> | null;

const GridField = ({
  name,
  form,
  options,
}: Props): GridFieldElement => {
  const {
    externalAutofillSource,
    labelText,
    columnSize,
    columnClass = '',
    includeToolTip = false,
    toolTipId = undefined,
    toolTipTargetId = undefined,
    ComponentType: CompType,
    componentProps = {},
    inputGroup,
    includeFormGroup = true,
    includeFormatting = true,
    showAutopopulationToggle = undefined,
    format,
    normalize,
  } = options;

  const { validateFieldName, fields } = modelTypesByForm[form];
  validateFieldName(name);

  const showAT = showAutopopulationToggle === undefined
    ? getAutofiller(name, fields) !== undefined
    : showAutopopulationToggle;

  const component = (typeof CompType === 'string')
    ? withInputGroup(
      inputGroup,
      (
        <Field
          form={form}
          id={name}
          name={name}
          type={CompType}
          externalAutofillSource={externalAutofillSource}
          component={RenderInput}
          format={format}
          normalize={normalize}
        />
      )
    )

    : (
      /* eslint-disable react/jsx-props-no-spreading */
      <CompType form={form} name={name} {...componentProps} />
      /* eslint-enable react/jsx-props-no-spreading */
    );

  const isCheckBox = CompType === 'checkbox';

  const tipId = toolTipId || name;

  const checkboxComp = (
    <FormGroup className="form-check">
      <Row>
        <Col lg={columnSize} className="pr-1">
          {component}
          {labelText && (
            <Label htmlFor={name} check>
              {labelText}
            </Label>
          )}
          {includeToolTip && (
          <>
            {' '}
            <Tip targetId={toolTipTargetId} tipId={tipId} />
          </>
          ) }
        </Col>
        { showAT && (
          <Col lg="2" className="pl-1">
            <FieldPopulationToggleLink fieldName={name} form={form} />
          </Col>
        )}
      </Row>
    </FormGroup>
  );

  const normalComp = (
    <>
      <Row>
        <Col className={columnClass}>
          {labelText && (
            <Label htmlFor={name}>
              {labelText}
            </Label>
          )}
          {includeToolTip && (
          <>
            {' '}
            <Tip targetId={toolTipTargetId} tipId={tipId} />
          </>
          ) }
        </Col>
      </Row>
      <Row>
        <Col lg={columnSize} className={`pr-1 ${columnClass}`}>
          {component}
          <FieldFeedback name={name} form={form} />
        </Col>
        { showAT && (
          <Col lg="2" className="pl-1">
            <FieldPopulationToggleLink fieldName={name} form={form} />
          </Col>
        )}
      </Row>
    </>
  );

  const normalCompWithFormGroup = includeFormGroup
    ? (
      <FormGroup>
        {normalComp}
      </FormGroup>
    )
    : normalComp;

  const noFormattingComp = (
    <div>
      <div className={flexRow}>
        <div>
          {labelText && (
            <Label htmlFor={name}>
              {labelText}
            </Label>
          )}
          {includeToolTip && (
          <>
            {' '}
            <Tip targetId={toolTipTargetId} tipId={tipId} />
          </>
          ) }
          {component}
        </div>
        { showAT && (
          <div>
            <FieldPopulationToggleLink fieldName={name} form={form} />
          </div>
        )}
      </div>
      <FieldFeedback name={name} form={form} />
    </div>
  );

  if (!includeFormatting) {
    return noFormattingComp;
  }
  if (isCheckBox) {
    return checkboxComp;
  }
  return normalCompWithFormGroup;
};

export default GridField;
