import {
  REDUX_FORM_ARRAY_POP,
  REDUX_FORM_ARRAY_PUSH,
  REDUX_FORM_ARRAY_REMOVE,
  REDUX_FORM_ARRAY_REMOVE_ALL,
  REDUX_FORM_ARRAY_SHIFT,
  REDUX_FORM_ARRAY_UNSHIFT,
} from '#constants/actionTypes.js';

const pop = (state, action, slice) => {
  const { meta } = action;
  return (state[slice][meta.field] || [])
    .filter((item, index, arr) => index !== arr.length - 1);
};

const push = (state, action, slice) => {
  const { meta } = action;
  return [
    ...(state[slice][meta.field] || []),
    {},
  ];
};

const remove = (state, action, slice) => {
  const { meta } = action;
  return (state[slice][meta.field] || [])
    .filter((item, index) => index !== meta.index);
};

const removeAll = () => [];

const shift = (state, action, slice) => {
  const { meta } = action;
  return (state[slice][meta.field] || [])
    .filter((item, index) => index !== 0);
};

const unshift = (state, action, slice) => {
  const { meta } = action;
  return [
    {},
    ...(state[slice][meta.field] || []),
  ];
};

export const fieldArrayActionsToOperations = {
  [REDUX_FORM_ARRAY_POP]: pop,
  [REDUX_FORM_ARRAY_PUSH]: push,
  [REDUX_FORM_ARRAY_REMOVE]: remove,
  [REDUX_FORM_ARRAY_REMOVE_ALL]: removeAll,
  [REDUX_FORM_ARRAY_SHIFT]: shift,
  [REDUX_FORM_ARRAY_UNSHIFT]: unshift,
};
