import { formValueSelector, arrayRemove, arrayPush } from 'redux-form';
import { REDUX_FORM_CHANGE } from '@hbrisk/sp3-risk-model-support/actions/types.js';
import requiredEdpsForBuildingTypes from '#support/models/buildingType/requiredEdpsForBuildingTypes/index.js';

const updateStructuralResponsesOnBuildingTypeChange = ({ store, action, form }) => {
  const { type } = action;
  if (type === REDUX_FORM_CHANGE) {
    const { meta: { field, form: actionForm } } = action;
    if (
      actionForm === form
      && ['buildingTypeDir1', 'buildingTypeDir2'].includes(field)
    ) {
      const state = store.getState();
      const {
        buildingTypeDir1, buildingTypeDir2, structuralResponses,
      } = formValueSelector(form)(state, 'buildingTypeDir1', 'buildingTypeDir2', 'structuralResponses');

      const requiredEdpKeys = requiredEdpsForBuildingTypes(buildingTypeDir1, buildingTypeDir2);

      const indexesToRemove = Array.isArray(structuralResponses)
        ? structuralResponses.reduceRight(
          (acc, response, index) => (
            response.method === 'sp3' && !requiredEdpKeys.includes(response.edpKey)
              ? [...acc, index]
              : acc
          ),
          []
        )
        : [];

      indexesToRemove.forEach((index) => {
        store.dispatch(arrayRemove(form, 'structuralResponses', index));
      });

      const missingEdpKeys = Array.isArray(structuralResponses)
        ? requiredEdpKeys.filter(
          (key) => !structuralResponses.some((response) => response.edpKey === key)
        ) : requiredEdpKeys;

      const itemsToAdd = missingEdpKeys.map((key) => ({ edpKey: key }));
      itemsToAdd.forEach((itemAsValues) => {
        store.dispatch(arrayPush(form, 'structuralResponses', itemAsValues));
      });
    }
  }
};

export default updateStructuralResponsesOnBuildingTypeChange;
