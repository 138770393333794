/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import {
  AUTOFILL_FORM_FIELD,
  SHOW_FIELD, HIDE_FIELD,
  INITIALIZE_FORM,
  UNAUTOFILL_FORM_FIELD,
} from '#constants/actionTypes.js';

export const showModelFormField = (form, field) => ({
  type: SHOW_FIELD,
  meta: {
    form,
  },
  payload: {
    name: field,
  },
});

export const hideModelFormField = (form, field) => ({
  type: HIDE_FIELD,
  meta: {
    form,
  },
  payload: {
    name: field,
  },
});

export const initializeModelFormFields = (form, payload) => ({
  type: INITIALIZE_FORM,
  meta: {
    form,
  },
  payload,
});

export const autofillModelFormField = (form, field, value) => ({
  type: AUTOFILL_FORM_FIELD,
  meta: { form, field },
  payload: value,
});

export const unautofillModelFormField = (form, field) => ({
  type: UNAUTOFILL_FORM_FIELD,
  meta: { form, field },
});
