import byField from '@hbrisk/sp3-risk-model-support/utility/form/formInputsTransforms/byField/index.js';
import clearAutofilledValues from '@hbrisk/sp3-risk-model-support/utility/form/clearAutofilledValues/index.js';

const calculateModelPutPayload = ({
  autofilled, visibleFields, values, fields,
}) => ({
  formInputs: byField(
    clearAutofilledValues({ autofilled, visibleFields, values }, fields),
    fields,
  ),
});

export default calculateModelPutPayload;
