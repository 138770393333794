/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';
import { pipe } from 'ramda';
import { initializeModelFormFields } from '#actions/models/modelForm.js';
import { modelTypesById } from '#src/js/constants/models/modelTypes/index.js';
import { selectModelHasOutputData, selectModelStatus } from '#selectors/entities/models.js';
import getComponent from './withFormInitialization.jsx';

const getContainer = (C) => {
  const mapStateToProps = (state, ownProps) => {
    const { modelType } = ownProps;
    const {
      selectFormInitialized,
      selectModelFormInputsBySliceWithAutofilledValues,
    } = modelTypesById[modelType];
    return ({
      payload: selectModelFormInputsBySliceWithAutofilledValues(state, ownProps),
      initialized: selectFormInitialized(state),
      modelHasOutputData: selectModelHasOutputData(state, ownProps),
      modelStatus: selectModelStatus(state, ownProps),
      ...ownProps,
    });
  };

  const mapDispatchToProps = (dispatch, ownProps) => ({
    initialize: (payload) => {
      dispatch(initializeModelFormFields(
        ownProps.form,
        payload
      ));
    },
  });

  return connect(mapStateToProps, mapDispatchToProps)(C);
};

export default pipe(
  getComponent,
  getContainer
);
