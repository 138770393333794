/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import { selectRepairTimeByReturnPeriod } from '#selectors/entities/models.js';
import RepairTimeTable from './RepairTimeTable.jsx';

const mapStateToProps = (state, ownProps) => {
  const repairTimeData = selectRepairTimeByReturnPeriod(state, ownProps);
  return {
    repairTimeData,
  };
};

export default connect(mapStateToProps)(RepairTimeTable);
