/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';
import { FORM_INPUT_NAMES } from '@hbrisk/sp3-risk-model-support/models/app/attributes/names/index.js';
import {
  selectIsStructuralResponseMethodMatch,
  selectStructuralResponseIsSuccessByEdpKey,
  selectStructuralResponseIsSuccessById,
} from '#selectors/entities/models.js';
import isUploadMethod from '#support/models/structuralResponses/structuralResponseIsUploadMethod.js';
import ResponseDetail from './ResponseDetail.jsx';
import { modelTypesByForm } from '#constants/models/modelTypes/index.js';

const mapStateToProps = (state, ownProps) => {
  const {
    modelId, edpKey, method, responseId, form, field,
  } = ownProps;
  const {
    selectFormFieldVisibility,
  } = modelTypesByForm[form];

  const isStructuralResponseUpload = isUploadMethod(method);
  const isMatch = selectIsStructuralResponseMethodMatch(state, { modelId, edpKey, method });
  const isSuccess = isStructuralResponseUpload
    ? selectStructuralResponseIsSuccessById(state, { modelId, responseId })
    : selectStructuralResponseIsSuccessByEdpKey(state, { modelId, edpKey });

  const showResponse = isMatch && isSuccess;
  const showUploader = selectFormFieldVisibility(
    state
  )(`${field}.${FORM_INPUT_NAMES.STRUCTURAL_RESPONSE_UPLOAD}`);

  const showResidualDriftDispersionDir1 = selectFormFieldVisibility(
    state
  )(`${field}.${FORM_INPUT_NAMES.RESIDUAL_DRIFT_DISPERSION_DIR_1}`);

  const showResidualDriftDispersionDir2 = selectFormFieldVisibility(
    state
  )(`${field}.${FORM_INPUT_NAMES.RESIDUAL_DRIFT_DISPERSION_DIR_2}`);

  const showResidualDriftDistributionModelDir1 = selectFormFieldVisibility(
    state
  )(`${field}.${FORM_INPUT_NAMES.RESIDUAL_DRIFT_DISTRIBUTION_MODEL_DIR_1}`);

  const showResidualDriftDistributionModelDir2 = selectFormFieldVisibility(
    state
  )(`${field}.${FORM_INPUT_NAMES.RESIDUAL_DRIFT_DISTRIBUTION_MODEL_DIR_2}`);

  const showResidualDriftModelDir1 = selectFormFieldVisibility(
    state
  )(`${field}.${FORM_INPUT_NAMES.RESIDUAL_DRIFT_MODEL_DIR_1}`);

  const showResidualDriftModelDir2 = selectFormFieldVisibility(
    state
  )(`${field}.${FORM_INPUT_NAMES.RESIDUAL_DRIFT_MODEL_DIR_2}`);

  const showResidualDriftRecordToRecordDispersionDir1 = selectFormFieldVisibility(
    state
  )(`${field}.${FORM_INPUT_NAMES.RESIDUAL_DRIFT_RECORD_TO_RECORD_DISPERSION_DIR_1}`);

  const showResidualDriftRecordToRecordDispersionDir2 = selectFormFieldVisibility(
    state
  )(`${field}.${FORM_INPUT_NAMES.RESIDUAL_DRIFT_RECORD_TO_RECORD_DISPERSION_DIR_2}`);

  return {
    showResponse,
    showUploader,
    showDir1Inputs: (
      showResidualDriftDispersionDir1
      || showResidualDriftDistributionModelDir1
      || showResidualDriftModelDir1
      || showResidualDriftRecordToRecordDispersionDir1
    ),
    showDir2Inputs: (
      showResidualDriftDispersionDir2
      || showResidualDriftDistributionModelDir2
      || showResidualDriftModelDir2
      || showResidualDriftRecordToRecordDispersionDir2
    ),
    showResidualDriftDispersionDir1,
    showResidualDriftDispersionDir2,
    showResidualDriftDistributionModelDir1,
    showResidualDriftDistributionModelDir2,
    showResidualDriftModelDir1,
    showResidualDriftModelDir2,
    showResidualDriftRecordToRecordDispersionDir1,
    showResidualDriftRecordToRecordDispersionDir2,
  };
};

export default connect(mapStateToProps)(ResponseDetail);
