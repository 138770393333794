/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import {
  RESIDUAL_DRIFT_SIMULATION_METHOD_VALUES,
} from '@hbrisk/sp3-risk-model-support/models/app/attributes/values/index.js';
import SelectMenu
  from '#components/pages/Models/Model/sections/support/forms/SelectMenu/index.js';

type Props = {|
  form: string,
  name: string,
|};

const {
  SIMULATE_FROM_MEDIAN_DRIFT,
  SIMULATE_FROM_REALIZED_DRIFT,
} = RESIDUAL_DRIFT_SIMULATION_METHOD_VALUES;

const values = [
  { id: SIMULATE_FROM_MEDIAN_DRIFT, name: 'Simulate from Median Drift' },
  { id: SIMULATE_FROM_REALIZED_DRIFT, name: 'Simulate from Realized Drift' },
];

const items = values.map(({ id, name }) => ({
  id,
  name,
  value: id,
}));

const ResidualDriftSimulationMethodSelectMenu = ({
  form,
  name,
}: Props): Element<typeof SelectMenu> => (
  <SelectMenu
    form={form}
    items={items}
    id={name}
    name={name}
  />
);

export default ResidualDriftSimulationMethodSelectMenu;
