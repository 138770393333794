import pipe from 'lodash/fp/pipe.js';
import { reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import withNavigate from '#components/support/routing/withNavigate/index.jsx';
import AddResponseForm from './AddResponseForm.jsx';
import { EDIT } from '#constants/pathStrings.js';

const STRUCTURAL_RESPONSE_ADD_FORM = 'structuralResponseAdd';

const mapStateToProps = (state, ownProps) => {
  const edpList = ownProps.fields.getAll().map((field) => field.edpKey);
  const selectedEdpKey = formValueSelector(STRUCTURAL_RESPONSE_ADD_FORM)(state, 'edpKey');
  const canAdd = selectedEdpKey && !edpList.includes(selectedEdpKey);
  const showError = selectedEdpKey && edpList.includes(selectedEdpKey);

  return {
    canAdd,
    showError,
  };
};

const makeOnSubmit = (fields, navigate) => (values) => {
  fields.unshift({ edpKey: values.edpKey });
  navigate(`../${EDIT}/0`);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  onSubmit: makeOnSubmit(ownProps.fields, ownProps.navigate),
});

const addForm = reduxForm({
  form: STRUCTURAL_RESPONSE_ADD_FORM,
})(AddResponseForm);

const structuralResponseAddForm = pipe(
  connect(mapStateToProps, undefined, mergeProps),
  withNavigate,
)(addForm);

export default structuralResponseAddForm;
