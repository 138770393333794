/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import defaultTo from 'lodash/fp/defaultTo.js';
import { connect } from 'react-redux';
import { FORM_INPUT_NAMES } from '@hbrisk/sp3-risk-model-support/models/app/attributes/names/index.js';
import { modelTypesByForm } from '#src/js/constants/models/modelTypes/index.js';
import { selectRunBuildingValueModifier, currentModelId } from '#selectors/entities/models.js';
import ScaleFactor from './ScaleFactor.jsx';

const mapStateToProps = (state, ownProps) => {
  const { form, locationItem } = ownProps;
  const { selectFormValues } = modelTypesByForm[form];

  const modelId = currentModelId(state);

  const costModifier = defaultTo(1, selectFormValues(state, `${locationItem}.costModifier`));
  const capacityModifier = defaultTo(1, selectFormValues(state, `${locationItem}.capacityModifier`));
  const timeModifier = defaultTo(1, selectFormValues(state, `${locationItem}.timeModifier`));
  const regionCostMultiplier = defaultTo(1, selectFormValues(state, 'regionCostMultiplier'));
  const dateCostMultiplier = defaultTo(1, selectFormValues(state, 'dateCostMultiplier'));
  const occupancyCostMultiplier = defaultTo(1, selectFormValues(state, 'occupancyCostMultiplier'));
  const componentSoftCostModifier = defaultTo(
    1,
    selectFormValues(state, FORM_INPUT_NAMES.COMPONENT_SOFT_COST_MODIFIER)
  );

  const buildingValueModifier = defaultTo(1, selectRunBuildingValueModifier(state, { modelId }));

  return {
    costModifier,
    capacityModifier,
    timeModifier,
    regionCostMultiplier,
    dateCostMultiplier,
    occupancyCostMultiplier,
    buildingValueModifier,
    componentSoftCostModifier,
  };
};

export default connect(mapStateToProps)(ScaleFactor);
