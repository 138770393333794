/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import get from 'lodash/fp/get.js';
import set from 'lodash/fp/set.js';
import unset from 'lodash/fp/unset.js';
import resolveFormInputs from '@hbrisk/sp3-risk-model-support/utility/form/resolveFormInputs/index.js';
import findFieldDefinition from '@hbrisk/sp3-risk-model-support/utility/form/findFieldDefinition/index.js';
import {
  AUTOFILL_FORM_FIELD,
  REDUX_FORM_ARRAY_POP,
  REDUX_FORM_ARRAY_PUSH,
  REDUX_FORM_ARRAY_REMOVE,
  REDUX_FORM_ARRAY_REMOVE_ALL,
  REDUX_FORM_ARRAY_SHIFT,
  REDUX_FORM_ARRAY_UNSHIFT,
  REDUX_FORM_BLUR,
  REDUX_FORM_CHANGE,
  INITIALIZE_FORM,
  SHOW_FIELD,
  HIDE_FIELD,
  UNAUTOFILL_FORM_FIELD,
} from '#constants/actionTypes.js';
import { fieldArrayActionsToOperations } from '#reducers/ui/form/support/makeFormReducerPlugin/fieldArrayOperations.js';

const makeFormReducerPlugin = ({
  fields,
  name,
}) => (state = {}, action = null) => {
  const actionForm = get('meta.form', action);
  if (actionForm !== name) {
    return state;
  }
  const { type, meta, payload } = action;

  switch (type) {
    case INITIALIZE_FORM:
      return {
        ...state,
        ...resolveFormInputs(
          fields,
          payload,
          action
        ),
        initial: payload.values,
      };
    case AUTOFILL_FORM_FIELD:
      return {
        ...state,
        ...resolveFormInputs(
          fields,
          {
            ...state,
            autofilled: set(meta.field, true, state.autofilled),
            values: set(meta.field, payload, state.values),
          },
          action
        ),
        syncErrors: unset(meta.field, state.syncErrors),
      };
    case UNAUTOFILL_FORM_FIELD:
      return {
        ...state,
        ...resolveFormInputs(
          fields,
          {
            ...state,
            autofilled: set(meta.field, false, state.autofilled),
          },
          action
        ),
      };
    case SHOW_FIELD:
      return {
        ...state,
        ...resolveFormInputs(
          fields,
          {
            ...state,
            visibleFields: set(payload.name, true, state.visibleFields),
          },
          action
        ),
      };
    case HIDE_FIELD: {
      return {
        ...state,
        ...resolveFormInputs(
          fields,
          {
            ...state,
            visibleFields: set(payload.name, false, state.visibleFields),
          },
          action,
        ),
      };
    }
    case REDUX_FORM_BLUR:
    case REDUX_FORM_CHANGE:
      return {
        ...state,
        ...resolveFormInputs(
          fields,
          state,
          action
        ),
      };
    case REDUX_FORM_ARRAY_PUSH:
    case REDUX_FORM_ARRAY_POP:
    case REDUX_FORM_ARRAY_REMOVE:
    case REDUX_FORM_ARRAY_REMOVE_ALL:
    case REDUX_FORM_ARRAY_SHIFT:
    case REDUX_FORM_ARRAY_UNSHIFT: {
      const {
        supportsNestedAutofilling,
        supportsNestedVisibility,
      } = findFieldDefinition(meta.field, fields);
      const operation = fieldArrayActionsToOperations[action.type];
      const updatedState = {
        ...state,
        ...(supportsNestedVisibility === true
          ? {
            visibleFields: set(
              meta.field,
              operation(state, action, 'visibleFields'),
              state.visibleFields
            ),
          } : {}
        ),
        ...(supportsNestedAutofilling === true
          ? {
            autofilled: set(
              meta.field,
              operation(state, action, 'autofilled'),
              state.autofilled
            ),
          } : {}
        ),
      };

      return {
        ...updatedState,
        ...resolveFormInputs(
          fields,
          updatedState,
          action
        ),
      };
    }
    default:
      return state;
  }
};

export default makeFormReducerPlugin;
