/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { FORM_INPUT_VALUES } from '@hbrisk/sp3-risk-model-support/models/app/attributes/values/index.js';

const structuralResponseIsUploadMethod = (method) => [
  FORM_INPUT_VALUES.STRUCTURAL_RESPONSE_METHOD_VALUES.MEDIANS,
  FORM_INPUT_VALUES.STRUCTURAL_RESPONSE_METHOD_VALUES.RHA,
].includes(method);

export default structuralResponseIsUploadMethod;
