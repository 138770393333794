import requiredEdpsForBuildingTypes from '#support/models/buildingType/requiredEdpsForBuildingTypes/index.js';

const getDefaultStructuralResponsesFieldArrayValue = (prev) => {
  const {
    buildingTypeDir1,
    buildingTypeDir2,
  } = prev.values;
  const requiredEdpKeys = requiredEdpsForBuildingTypes(buildingTypeDir1, buildingTypeDir2);
  return requiredEdpKeys
    .map((edpKey) => ({ edpKey }));
};

export default getDefaultStructuralResponsesFieldArrayValue;
