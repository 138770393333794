/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import {
  FormGroup, Row, Col,
} from 'reactstrap';
import { FORM_INPUT_NAMES } from '@hbrisk/sp3-risk-model-support/models/app/attributes/names/index.js';
import Tip from '#components/support/tooltips/Tip/index.jsx';
import GridField from '#components/pages/Models/Model/sections/support/forms/GridField/index.jsx';
import { edpsByKey } from '#constants/edps/index.js';
import type { EdpKey } from '#constants/edps/index.js';
import StructuralResponseTable from '#components/pages/Models/Model/sections/type2/StructuralResponsesSection/EditView/Response/ResponseDetail/Table/index.js';
import StructuralResponsePlot from '#components/pages/Models/Model/sections/type2/StructuralResponsesSection/EditView/Response/ResponseDetail/Plot/index.js';
import StructuralResponseMethodSelectMenu from '#components/pages/Models/Model/sections/type2/StructuralResponsesSection/support/SourceSelectMenu/index.jsx';
import StructuralResponseUploader from './Uploader/index.js';
import ResidualDriftModelSelectMenu
  from '#components/pages/Models/Model/sections/type2/StructuralResponsesSection/EditView/Response/ResponseDetail/ResidualDrift/DriftModelSelectMenu/index.jsx';
import ResidualDriftDistributionModelSelectMenu
  from '#components/pages/Models/Model/sections/type2/StructuralResponsesSection/EditView/Response/ResponseDetail/ResidualDrift/DistributionModelSelectMenu/index.jsx';

type EdpResponseProps = {
  modelId: string,
  form: string,
  field: string,
  responseIndex: number,
  edpKey: EdpKey,
  method: string,
  showUploader: boolean,
  showResponse: boolean,
  showDir1Inputs: boolean,
  showDir2Inputs: boolean,
  showResidualDriftDispersionDir1: boolean,
  showResidualDriftDispersionDir2: boolean,
  showResidualDriftDistributionModelDir1: boolean,
  showResidualDriftDistributionModelDir2: boolean,
  showResidualDriftModelDir1: boolean,
  showResidualDriftModelDir2: boolean,
  showResidualDriftRecordToRecordDispersionDir1: boolean,
  showResidualDriftRecordToRecordDispersionDir2: boolean,
}

const ResponseDetail = ({
  form,
  modelId,
  field,
  edpKey,
  responseIndex,
  method,
  showResponse,
  showUploader,
  showDir1Inputs,
  showDir2Inputs,
  showResidualDriftDispersionDir1,
  showResidualDriftDispersionDir2,
  showResidualDriftDistributionModelDir1,
  showResidualDriftDistributionModelDir2,
  showResidualDriftModelDir1,
  showResidualDriftModelDir2,
  showResidualDriftRecordToRecordDispersionDir1,
  showResidualDriftRecordToRecordDispersionDir2,
}: EdpResponseProps): Element<'div'> => {
  const edp = edpsByKey[edpKey];
  const { excludedMethods } = edp;
  const plotDir1 = showResponse
    ? (
      <Row>
        <Col>
          <StructuralResponsePlot
            modelId={modelId}
            edpKey={edpKey}
            direction={1}
          />
        </Col>
      </Row>
    ) : null;
  const plotDir2 = showResponse
    ? (
      <Row>
        <Col>
          <StructuralResponsePlot
            modelId={modelId}
            edpKey={edpKey}
            direction={2}
          />
        </Col>
      </Row>
    ) : null;
  const tableDir1 = showResponse
    ? (
      <Row>
        <Col>
          <StructuralResponseTable
            modelId={modelId}
            edpKey={edpKey}
            direction={1}
          />
        </Col>
      </Row>
    ) : null;
  const tableDir2 = showResponse
    ? (
      <Row>
        <Col>
          <StructuralResponseTable
            modelId={modelId}
            edpKey={edpKey}
            direction={2}
          />
        </Col>
      </Row>
    ) : null;
  return (
    <div className={`${edpKey}-response-detail-view`} key={`response-${responseIndex}`}>
      <h3>
        {edp.name}
        {' '}
        <Tip tipId={edpKey} />
      </h3>
      <GridField
        name={`${field}.method`}
        form={form}
        options={{
          componentProps: {
            exclude: excludedMethods || [],
          },
          columnSize: 5,
          labelText: 'Source',
          ComponentType: StructuralResponseMethodSelectMenu,
        }}
      />
      { showUploader && (
        <StructuralResponseUploader
          form={form}
          name={`${field}.upload`}
          modelId={modelId}
          edpKey={edpKey}
          method={method}
          accept=".csv"
          maxBytes={1.6e7}
        />
      )}
      <div>
        {
          (showResponse || showDir1Inputs) && (
            <FormGroup tag="fieldset">
              <legend>Direction 1</legend>
              {
                showResidualDriftModelDir1
                && (
                  <GridField
                    name={`${field}.${FORM_INPUT_NAMES.RESIDUAL_DRIFT_MODEL_DIR_1}`}
                    form={form}
                    options={{
                      columnSize: 5,
                      labelText: 'Residual Drift Model',
                      ComponentType: ResidualDriftModelSelectMenu,
                      includeFormGroup: true,
                      showAutopopulationToggle: true,
                      includeToolTip: true,
                      toolTipId: 'residualDriftModel',
                    }}
                  />
                )
              }
              {
                showResidualDriftDistributionModelDir1
                && (
                  <GridField
                    name={`${field}.${FORM_INPUT_NAMES.RESIDUAL_DRIFT_DISTRIBUTION_MODEL_DIR_1}`}
                    form={form}
                    options={{
                      columnSize: 5,
                      labelText: 'Distribution Model',
                      ComponentType: ResidualDriftDistributionModelSelectMenu,
                      includeFormGroup: true,
                      showAutopopulationToggle: true,
                      includeToolTip: true,
                      toolTipId: 'residualDriftDistributionModel',
                    }}
                  />
                )
              }
              {
                showResidualDriftDispersionDir1
                && (
                  <GridField
                    name={`${field}.${FORM_INPUT_NAMES.RESIDUAL_DRIFT_DISPERSION_DIR_1}`}
                    form={form}
                    options={{
                      columnSize: 5,
                      labelText: 'Dispersion/COV',
                      ComponentType: 'text',
                      includeFormGroup: true,
                      showAutopopulationToggle: true,
                      includeToolTip: true,
                      toolTipId: 'residualDriftDispersion',
                    }}
                  />
                )
              }
              {
                showResidualDriftRecordToRecordDispersionDir1
                && (
                  <GridField
                    name={`${field}.${FORM_INPUT_NAMES.RESIDUAL_DRIFT_RECORD_TO_RECORD_DISPERSION_DIR_1}`}
                    form={form}
                    options={{
                      columnSize: 5,
                      labelText: 'Record to Record Dispersion',
                      ComponentType: 'text',
                      includeFormGroup: true,
                      showAutopopulationToggle: true,
                      includeToolTip: true,
                      toolTipId: 'residualDriftRecordToRecordDispersion',
                    }}
                  />
                )
              }
              {plotDir1}
              {tableDir1}
            </FormGroup>
          )
        }
        {
          (showResponse || showDir2Inputs) && (
            <FormGroup tag="fieldset">
              <legend>Direction 2</legend>
              {
                showResidualDriftModelDir2
                && (
                  <GridField
                    name={`${field}.${FORM_INPUT_NAMES.RESIDUAL_DRIFT_MODEL_DIR_2}`}
                    form={form}
                    options={{
                      columnSize: 5,
                      labelText: 'Residual Drift Model',
                      ComponentType: ResidualDriftModelSelectMenu,
                      includeFormGroup: true,
                      showAutopopulationToggle: true,
                      includeToolTip: true,
                      toolTipId: 'residualDriftModel',
                    }}
                  />
                )
              }
              {
                showResidualDriftDistributionModelDir2
                && (
                  <GridField
                    name={`${field}.${FORM_INPUT_NAMES.RESIDUAL_DRIFT_DISTRIBUTION_MODEL_DIR_2}`}
                    form={form}
                    options={{
                      columnSize: 5,
                      labelText: 'Distribution Model',
                      ComponentType: ResidualDriftDistributionModelSelectMenu,
                      includeFormGroup: true,
                      showAutopopulationToggle: true,
                      includeToolTip: true,
                      toolTipId: 'residualDriftDistributionModel',
                    }}
                  />
                )
              }
              {
                showResidualDriftDispersionDir2
                && (
                  <GridField
                    name={`${field}.${FORM_INPUT_NAMES.RESIDUAL_DRIFT_DISPERSION_DIR_2}`}
                    form={form}
                    options={{
                      columnSize: 5,
                      labelText: 'Dispersion/COV',
                      ComponentType: 'text',
                      includeFormGroup: true,
                      showAutopopulationToggle: true,
                      includeToolTip: true,
                      toolTipId: 'residualDriftDispersion',
                    }}
                  />
                )
              }
              {
                showResidualDriftRecordToRecordDispersionDir2
                && (
                  <GridField
                    name={`${field}.${FORM_INPUT_NAMES.RESIDUAL_DRIFT_RECORD_TO_RECORD_DISPERSION_DIR_2}`}
                    form={form}
                    options={{
                      columnSize: 5,
                      labelText: 'Record to Record Dispersion',
                      ComponentType: 'text',
                      includeFormGroup: true,
                      showAutopopulationToggle: true,
                      includeToolTip: true,
                      toolTipId: 'residualDriftRecordToRecordDispersion',
                    }}
                  />
                )
              }
              {plotDir2}
              {tableDir2}
            </FormGroup>
          )
        }
      </div>
    </div>
  );
};

export default ResponseDetail;
