/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import { Button, FormGroup, Table } from 'reactstrap';
import GridField from '#components/pages/Models/Model/sections/support/forms/GridField/index.jsx';
import Tip from '#components/support/tooltips/Tip/index.jsx';
import isFloor from '#support/models/isFloor.js';
import getLocationDefaults from '#components/pages/Models/Model/sections/type2/BuildingComponentsTableSection/support/getLocationDefaults/index.js';
import type { EdpLocationType } from '#constants/edps/index.js';
import ScaleFactor from './ScaleFactor/index.js';

type Props = {|
  autofilled: boolean,
  fields: {
    get: Function,
    map: Function,
    push: Function,
    remove: Function,
    name: string,
  },
  form: string,
  edpLocationType: EdpLocationType,
  isDirectional: boolean,
  meta: {
    error: string,
  },
|};

const renderLocations = ({
  autofilled, edpLocationType, fields, form, isDirectional, meta: { error },
}: Props): Element<typeof FormGroup> => {
  const quantityHeadings = isDirectional
    ? (
      <>
        <th>
          Quantity
          {' '}
          <span className="text-nowrap">
            Direction 1
            {' '}
            <Tip tipId="quantityDir1" />
          </span>
        </th>
        <th>
          Quantity
          {' '}
          <span className="text-nowrap">
            Direction 2
            {' '}
            <Tip tipId="quantityDir2" />
          </span>
        </th>
      </>
    ) : (
      <th>
        Quantity
        <Tip tipId="quantityNonDir" />
      </th>
    );

  return (
    <FormGroup tag="fieldset">
      <legend>Locations</legend>
      <Table>
        <thead>
          <tr>
            <th>
              {isFloor(edpLocationType) ? 'Floors' : 'Stories'}
              {' '}
              <Tip tipId={edpLocationType} />
            </th>
            {quantityHeadings}
            <th>
              Cost Scale Factor
              {' '}
              <Tip tipId="costScaleFactor" />
            </th>
            <th>
              Capacity Scale Factor
              {' '}
              <Tip tipId="capacityScaleFactor" />
            </th>
            <th>
              Time Scale Factor
              {' '}
              <Tip tipId="timeScaleFactor" />
            </th>
          </tr>
        </thead>
        <tbody>
          {
            error ? null : (
              fields.map((locationItem, index) => {
                const quantityFields = isDirectional
                  ? (
                    <>
                      <td>
                        <GridField
                          name={`${locationItem}.quantityDir1`}
                          form={form}
                          options={{
                            ComponentType: 'text',
                            includeFormatting: false,
                            externalAutofillSource: 'componentPopulation',
                          }}
                        />
                      </td>
                      <td>
                        <GridField
                          name={`${locationItem}.quantityDir2`}
                          form={form}
                          options={{
                            ComponentType: 'text',
                            includeFormatting: false,
                            externalAutofillSource: 'componentPopulation',
                          }}
                        />
                      </td>
                    </>
                  ) : (
                    <td>
                      <GridField
                        name={`${locationItem}.quantityNonDir`}
                        form={form}
                        options={{
                          ComponentType: 'text',
                          includeFormatting: false,
                          externalAutofillSource: 'componentPopulation',
                        }}
                      />
                    </td>
                  );

                return (
                  <>
                    <tr className="border-bottom-0" key={locationItem}>
                      <td>
                        <GridField
                          name={`${locationItem}.locationSpecifier`}
                          form={form}
                          options={{
                            ComponentType: 'text',
                            includeFormatting: false,
                            externalAutofillSource: 'componentPopulation',
                            format: (value) => value && value.replace('g', 'G').replace('r', 'R'),
                            normalize: (value) => value && value.replace('g', 'G').replace('r', 'R'),
                          }}
                        />
                      </td>
                      {quantityFields}
                      <td>
                        <GridField
                          name={`${locationItem}.costModifier`}
                          form={form}
                          options={{
                            ComponentType: 'text',
                            includeFormatting: false,
                            externalAutofillSource: 'componentPopulation',
                          }}
                        />
                      </td>
                      <td>
                        <GridField
                          name={`${locationItem}.capacityModifier`}
                          form={form}
                          options={{
                            ComponentType: 'text',
                            includeFormatting: false,
                            externalAutofillSource: 'componentPopulation',
                          }}
                        />
                      </td>
                      <td>
                        <GridField
                          name={`${locationItem}.timeModifier`}
                          form={form}
                          options={{
                            ComponentType: 'text',
                            includeFormatting: false,
                            externalAutofillSource: 'componentPopulation',
                          }}
                        />
                      </td>
                      <td>
                        <Button
                          id={`${locationItem}.deleteBtn`}
                          color="link"
                          disabled={autofilled}
                          onClick={() => fields.remove(index)}
                          size="sm"
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                    <ScaleFactor
                      form={form}
                      locationItem={locationItem}
                      isDirectional={isDirectional}
                    />
                  </>
                );
              })
            )
          }
        </tbody>
      </Table>
      <div>
        <span className="text-danger">{error}</span>
        <Button
          id={`${fields.name}.addLocationBtn`}
          className="float-right"
          color="primary"
          disabled={autofilled}
          onClick={() => {
            fields.push(getLocationDefaults());
          }}
          size="sm"
          type="button"
        >
          + Add Location
        </Button>
      </div>
    </FormGroup>
  );
};

export default renderLocations;
