/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import get from 'lodash/fp/get.js';
import keyBy from 'lodash/fp/keyBy.js';
import { mapObjIndexed } from 'ramda';
import { MODEL_TYPE_1_ID, MODEL_TYPE_2_ID } from '@hbrisk/sp3-risk-model-support/models/modelTypes/ids.js';
import makeFormValidator from '@hbrisk/sp3-risk-model-support/utility/form/validation/makeFormValidator/index.js';
import findFieldDefinition from '@hbrisk/sp3-risk-model-support/utility/form/findFieldDefinition/index.js';
import getAutofiller from '@hbrisk/sp3-risk-model-support/utility/form/getAutofiller/index.js';
import { TYPE_1_MODEL_FORM } from '#constants/models/form/model/type1/name.js';
import { TYPE_2_MODEL_FORM } from '#constants/models/form/model/type2/name.js';
import {
  selectActiveSectionGroups,
  selectActiveSectionsInActiveGroups,
  selectActiveSectionsByGroup,
  selectErrorCountBySection,
  selectInitialized,
  selectWarningCountBySection,
} from '#selectors/ui/modelPage.js';
import * as formSelectors from '#selectors/ui/form/model.js';
import {
  makeAutofilledValueSelector,
  makePickedAutofilledValuesSelector,
  selectModelFormInputsBySliceWithAutofilledValues,
} from '#selectors/entities/models.js';

import type1Fields from '#constants/models/form/model/type1/fields.js';
import type1SectionGroups from '#constants/models/modelPage/type1/sectionGroups.js';
import type1Sections from '#constants/models/modelPage/type1/sections.js';
import type2Fields from '#constants/models/form/model/type2/fields.js';
import type2SectionGroups from '#constants/models/modelPage/type2/sectionGroups.js';
import type2Sections from '#constants/models/modelPage/type2/sections.js';

const modelType1Base = {
  allSections: type1Sections,
  allSectionGroups: type1SectionGroups,
  description: 'Use for seismic risk assessment of existing buildings (due diligence or similar).',
  fields: type1Fields,
  id: MODEL_TYPE_1_ID,
  modelFormName: TYPE_1_MODEL_FORM,
  name: 'Standard Model',
  shortName: 'Std',
  runButtonRunningText: 'Running ...',
  runButtonText: 'Run Model',
  shouldRedirectOnSubmit: true,
  shouldShowRetrofitInfo: true,
};

const modelType2Base = {
  allSections: type2Sections,
  allSectionGroups: type2SectionGroups,
  description: 'Use for resilient design or detailed risk assessment of new or existing buildings.',
  fields: type2Fields,
  id: MODEL_TYPE_2_ID,
  modelFormName: TYPE_2_MODEL_FORM,
  name: 'Advanced Model',
  shortName: 'Adv',
  runButtonRunningText: 'Running ...',
  runButtonText: 'Update and Run Model',
  shouldRedirectOnSubmit: false,
  shouldShowRetrofitInfo: false,
};

const makeFieldNameValidator = (fields) => (fullyQualifiedFieldName) => {
  const definition = findFieldDefinition(fullyQualifiedFieldName, fields);
  if (definition === undefined) {
    throw new Error(`Field ${fullyQualifiedFieldName} is not defined in modelTypes constant`);
  }
  return undefined;
};

const getIsFieldInSection = (fieldsByName) => (field, section) => get(`${field}.section`, fieldsByName) === section;

const addDerived = (type) => {
  const {
    allSectionGroups, allSections, fields, id, modelFormName,
  } = type;
  const fieldsByName = keyBy('name', fields);
  const allSectionsById = keyBy('id', allSections);
  const formSelectorFunctions = mapObjIndexed(
    (selector) => selector(modelFormName),
    formSelectors
  );

  const isAutofillable = (
    fullyQualifiedFieldName
  ) => getAutofiller(fullyQualifiedFieldName) !== undefined;

  return ({
    ...type,
    allSectionsById,
    fieldsByName,
    isAutofillable,
    isFieldInSection: getIsFieldInSection(fieldsByName),
    selectModelFormInputsBySliceWithAutofilledValues:
      selectModelFormInputsBySliceWithAutofilledValues(fields),
    makeAutofilledValueSelector: makeAutofilledValueSelector(fields),
    makePickedAutofilledValuesSelector: makePickedAutofilledValuesSelector(fields),
    selectActiveSectionGroups: selectActiveSectionGroups(id, allSectionGroups),
    selectActiveSectionsInActiveGroups: selectActiveSectionsInActiveGroups(id, allSections),
    selectActiveSectionsByGroup:
      selectActiveSectionsByGroup(fieldsByName, modelFormName, id, allSections),
    selectErrorCountBySection: selectErrorCountBySection(fieldsByName, modelFormName),
    selectWarningCountBySection: selectWarningCountBySection(fieldsByName, modelFormName),
    selectModelPageInitialized: selectInitialized(id),
    validate: makeFormValidator(fields),
    validateFieldName: makeFieldNameValidator(fields),
    ...formSelectorFunctions,
  });
};

const type1 = addDerived(modelType1Base);
const type2 = addDerived(modelType2Base);

const modelTypes = [
  type1,
  type2,
];

const modelTypesById = keyBy('id', modelTypes);
const modelTypesByForm = keyBy('modelFormName', modelTypes);

export {
  MODEL_TYPE_1_ID,
  MODEL_TYPE_2_ID,
  modelTypes,
  modelTypesById,
  modelTypesByForm
};
